.snackbar-success {
    --mdc-snackbar-container-color: #7023c9;
    --mat-snack-bar-button-color: white;
}

.snackbar-warning {
    --mdc-snackbar-container-color: #ffb22b;
    --mat-snack-bar-button-color: white;
}

.snackbar-error {
    --mdc-snackbar-container-color: #E57373;
    --mat-snack-bar-button-color: white;
}